import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/react';
import { setName } from '../extra/util';
import SanityImage from 'gatsby-plugin-sanity-image';
import SEO from '../components/seo';
import BlockContent from '../components/block-content';
import Gallery from '../components/gallery';
import Card from '../components/card';

export default function Work({ data }) {
  const lf = new Intl.ListFormat('en-AU');

  setName(data.work?.title);

  return (
    <div
      className="type--50"
      css={css`
        color: var(--black);
        background-color: ${data.work.collection?.color?.hex};
        padding: var(--margin);

        a {
          text-decoration-color: var(--white);

          &:hover {
            text-decoration-color: var(--black);
          }
        }
      `}
    >
      <div
        css={css`
          text-transform: uppercase;
          text-align: center;
          margin-bottom: var(--largeMargin);
        `}
      >
        <h1
          className="type--50"
        >
          {data.work.people.length > 0 && lf.format(data.work.people.map((person) => person.name))}<br />
          &ldquo;{data.work.title}&rdquo;
        </h1>
      </div>
      <BlockContent
        className="type--35"
        css={css`
          margin-bottom: var(--extraLargeMargin);

          p, .rule, blockquote {
            max-width: 900px;
            margin-right: auto;
            margin-left: auto;
          }
        `}
        content={data.work._rawContent}
      />
      {data.work.images.length > 0 && <Gallery images={data.work.images} />}
      {data.work.people.length > 0 && <div
        css={css`
          max-width: 920px;
          padding-top: var(--gap);
          border-top: var(--borderWidth) solid var(--white);
          margin: 0 auto;
          margin-bottom: var(--extraLargeMargin);
        `}
      >
        {data.work.people.map((person, i) => <div
          key={i}
        >
          <BlockContent
            className="type--20--important"
            css={css`
              margin-bottom: var(--margin);
            `}
            content={person._rawDescription}
          />
        </div>)}
      </div>}
    </div>
  );
}

export function Head({ data }) {
  return <SEO title={`West Space – ${data.work?.title}`} />
}

export const query = graphql`
  query WorkTemplateQuery($slug: String!) {
    work: sanityWork(slug: { current: { eq: $slug } }) {
      id
      title
      collection {
        color {
          hex
        }
      }
      featuredImage {
        ...Image
      }
      _rawContent(resolveReferences: { maxDepth: 10 })
      people {
        name
        _rawDescription(resolveReferences: { maxDepth: 10 })
      }
      images {
        ...Image
      }
    }
  }
`;
